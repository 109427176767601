import React from "react";
import styles from "./styles";

export default function BlogPost({ pageContext }) {
  const { html } = pageContext;
  const { Name } = pageContext;
  const classes = styles();

  return (
    <div>
    <h1 className={classes.h1}>{Name}</h1>
    <div
    className={classes.body}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
    </div>
  );
}
