import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  h1: {
    textTransform: "uppercase",
    fontFamily: "Helvetica, Roboto, sans-serif"
  },
  body: {
    fontFamily: "Arial, Roboto, sans-serif",
    "& a": {
      textDecoration: 'none',
      color: '#000'
    },
    '& h2': {
      textTransform: 'uppercase'
    }
  },
}));
export default styles;
